<template>
  <ion-header>
    <ion-toolbar class="background-color toolbar">
      <ion-buttons slot="start" class="white">
        <ion-button color="light" @click="showMenu">
          <ion-icon slot="icon-only" :icon="menu()" color="light"/>
        </ion-button>
      </ion-buttons>
      <ion-title slot="start" class="white">
        <div class="row"
             style="margin: 0;flex-wrap: nowrap;align-items: center;align-content: center;flex-direction: row; justify-content: center;">
          <div style="display: inherit;">
            Scrapl.us &nbsp;<template v-if="hasLogin">
            <p style="cursor:pointer;font-size: 15px;" @click="gotoVip()">{{ isVip ? '' : isUnpaid ? '' : 'Normal' }}</p>
          </template>
          </div>
          <div v-if="!isVip && isUnpaid" style="position: relative;" @click="retryPayment">
            <ion-icon :icon="diamondSharp()" color="warning" style="font-size: 30px;"/>
            <p class="ion-hide-lg-down" style="position:absolute;    top: 12px;
    right: -60px;
    font-size: 15px;color: #ffc409; font-weight: bolder">Premium</p>
          </div>
          <div v-else-if="isVip" style="position: relative;">
            <ion-icon :icon="diamondSharp()" v-if="isVip" style="font-size: 30px;"/>
            <p class="ion-hide-lg-down" v-if="isVip" style="position:absolute;    top: 12px;
    right: -60px;
    font-size: 15px;">Premium</p>
          </div>
        </div>
      </ion-title>
      <ion-buttons v-if="hasLogin" slot="end">
        <ion-button @click="goToProfile" class="white d-none d-lg-block" color="light" shape="round">{{ username }}
        </ion-button>

        <ion-button @click="goToRoute('message')" style="--padding-top: 0;
    --padding-end: 0;
    --padding-bottom: 0;
    --padding-start: 0;
    --border-radius: 0%;
    margin-right: 5px;
    max-width: 48px;
    min-width: 48px;">

          <ion-icon slot="icon-only" :icon="mail()" color="light" style="max-width:32px;min-width: 32px;">
          </ion-icon>
          <ion-badge color="danger" style="position: absolute; right: 0; top: 0;" v-if="mailCount > 0">
            {{ mailCount }}
          </ion-badge>

        </ion-button>
        <ion-button @click="showNotifications" style="--padding-top: 0;
    --padding-end: 0;
    --padding-bottom: 0;
    --padding-start: 0;
    --border-radius: 0%;
    margin-right: 5px;
    max-width: 48px;
    min-width: 48px;">

          <img :src="avatar" alt="profile" style="max-width:32px;min-width: 32px;border-radius: 5px;"/>
          <ion-badge color="danger" style="position: absolute; right: 0; top: 0;" v-if="notificationCount > 0">
            {{ notificationCount }}
          </ion-badge>

        </ion-button>
      </ion-buttons>
      <ion-buttons v-else slot="end">
        <ion-button @click="goToLogin" class="white d-none d-md-block" color="light" shape="round">Giriş Yap veya Kayıt
          Ol
        </ion-button>
        <ion-button @click="goToLogin" class="white" color="light" shape="round">
          <ion-icon slot="icon-only" :icon="logInOutline()" color="light"></ion-icon>
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>
  <ion-menu v-if="hasLogin" side="end" menu-id="menu-notifications" content-id="main">
    <ion-header>
      <ion-toolbar class="background-color">
        <ion-title class="white">Sana Özel</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content id="notification-menu">
      <ion-list lines="full">
        <template v-for="specialItem in specialItems" :key="specialItem.id">
          <ion-item button @click="goToRoute(specialItem.href,'menu-notifications')">
            <ion-icon :icon="specialItem.icon"/>
            <ion-label>{{ specialItem?.name }}</ion-label>
            <ion-badge v-if="specialItem.hasCount && this[specialItem.countName] > 0" color="danger">
              {{ this[specialItem.countName] }}
            </ion-badge>
          </ion-item>
        </template>
      </ion-list>
    </ion-content>
  </ion-menu>
  <ion-menu side="start" menu-id="menu-main" content-id="main">
    <ion-header>
      <ion-toolbar class="background-color">
        <ion-title class="white">Menü</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content id="main-menu">
      <ion-list lines="full">
        <template v-for="item in items" :key="item.name">
          <ion-item button @click="router.push('/'+item.href);menuController.close('menu-main')"
                    v-if="item.isLogin === undefined || item.isLogin === hasLogin">
            <ion-icon :icon="item.icon"/>
            <ion-label>{{ item.name }}</ion-label>
          </ion-item>
        </template>
      </ion-list>
    </ion-content>
  </ion-menu>
</template>
<script>

import {
  IonHeader,
  IonList,
  IonItem,
  IonTitle,
  IonToolbar,
  IonMenu,
  IonContent,
  IonButtons,
  IonLabel,
  IonIcon, menuController, IonButton, IonBadge,
} from "@ionic/vue";
import {useRouter} from 'vue-router';
import {
  home,
  logInOutline,
  newspaperSharp,
  chatbubbles,
  notifications as notificationsIcon,
  star,
  documentText,
  personOutline,
  notificationsOutline,
  menu,
  mailOutline,
  mailUnread,
  person,
  diamondSharp,
  folder,
  folderOpen,
  leaf,
  receipt
} from 'ionicons/icons';
import {ref} from "@vue/runtime-core";

export default {
  name: "HeaderComponent",
  computed: {
    menuController() {
      return menuController
    }
  },
  components: {
    IonHeader,
    IonList,
    IonItem,
    IonTitle,
    IonToolbar,
    IonMenu,
    IonBadge,
    IonContent,
    IonButtons,
    IonButton,
    IonLabel,
    IonIcon,
  },
  setup() {
    const router = ref(useRouter());
    return {
      router
    }
  },
  mounted() {
    this.$emitter.on('updateProfile', (profile) => {
      profile.then((profileData) => {
        this.hasLogin = !!profileData;
        this.isVip = profileData?.isVip;
        this.isUnpaid = profileData?.subscription_status === 'UNPAID';
        this.username = profileData.name;
        this.avatar = profileData.avatar;
        this.notificationCount = profileData.unreadNotificationCount ?? 0;
        this.mailCount = profileData.unreadMailCount ?? 0;
        this.notificationIcon = notificationsOutline;
        this.mailIcon = mailOutline;
        this.notificationIcon = notificationsOutline;
        if (this.notificationCount > 0) {
          this.notificationIcon = notificationsIcon;
        }
        if (this.mailCount > 0) {
          this.mailIcon = mailUnread;
        }
      });
    });
  },
  created() {
    if (this.hasLogin) {
      this.username = this.$api.auth?.name;
      this.isVip = this.$api.auth?.isVip;
      this.isUnpaid = this.$api.auth?.subscription_status === 'UNPAID';
      this.avatar = this.$api.auth?.avatar;

      this.notificationCount = this.$api.auth?.unreadNotificationCount ?? 0;
      this.mailCount = this.$api.auth?.unreadMailCount ?? 0;
      this.mailIcon = mailOutline;
      this.notificationIcon = notificationsOutline;
      if (this.notificationCount > 0) {
        this.notificationIcon = notificationsIcon;
      }
      if (this.mailCount > 0) {
        this.mailIcon = mailUnread;
      }
    }
  },
  methods: {
    retryPayment() {

    },
    gotoVip() {
      this.$emitter.emit('calling_vip')
    },
    diamondSharp() {
      return diamondSharp
    },
    mail() {
      return this.mailIcon
    },
    menu() {
      return menu
    },
    showNotifications() {
      menuController.open('menu-notifications');
      menuController.enable(true, 'menu-notifications');

    },
    showMenu() {
      menuController.open('menu-main');
      menuController.enable(true, 'menu-main');

    },
    notifications() {
      return this.notificationIcon
    },
    personOutline() {
      return personOutline
    },
    logInOutline() {
      return logInOutline
    },
    goToProfile: function () {
      this.$router.push('/profile');
    },
    goToLogin: function () {
      this.$router.push('/login');
    },
    goToRoute: function (href, menu) {
      this.$router.push('/' + href);
      menuController.close(menu)
    }
  },
  data() {
    return {
      notificationIcon: notificationsOutline,
      notificationCount: 0,
      mailIcon: mailOutline,
      mailCount: 0,
      username: '',
      isVip: false,
      isUnpaid: false,
      avatar: null,
      notificationData: [
        {id: 1, title: 'Mesaj Geldi'},
        {id: 1, title: 'Mesaj Geldi'},
        {id: 1, title: 'Mesaj Geldi'},
      ],
      specialItems: [
        {name: 'Profil', icon: person, href: 'profile', isLogin: true},
        {name: 'İlanlarım', icon: documentText, href: 'my-list', isLogin: true},
        {name: 'Favori İlanlar', icon: star, href: 'favorite-list', isLogin: true},
        {
          name: 'Bildirimler',
          icon: notificationsIcon,
          href: 'notification',
          isLogin: true,
          hasCount: true,
          countName: 'notificationCount'
        },
        {name: 'Mesajlar', icon: chatbubbles, href: 'message', isLogin: true, hasCount: true, countName: 'mailCount'},
        {name: 'Çıkış Yap', icon: logInOutline, href: 'logout', isLogin: true},
      ],
      items: [
        {name: 'Anasayfa', icon: home, href: 'home'},
        {name: 'İlanlar', icon: newspaperSharp, href: 'list'},
        {name: 'Hakkımızda', icon: leaf, href: 'about-us'},
        {name: 'Gizlilik Politikası', icon: folder, href: 'policies'},
        {name: 'Teslimat ve İade', icon: folderOpen, href: 'return-policies'},
        {name: 'İletişim', icon: receipt, href: 'contact'},
      ],
      hasLogin: !!this.$cookies.get('token'),
    }
  }
};
</script>
<style>
.background-color {
  --background: #43a047;
}

.white {
  color: white;
}

ion-toolbar ion-button {
  min-height: 48px;
}
</style>