<template>
  <ion-app>
    <Transition name="fade">
      <ion-content v-if="progressCompleted === false">
        <Loading/>
        <div style="    height: 100vh;
    display: grid;
    position: fixed;
    width: 100vw;background-image: url('assets/bg.png');background-color: #56B35A;    background-repeat: no-repeat;
    background-position: bottom center;
    background-size: 58vw;">
          <div style="text-align: center;
    margin: auto;">
            <h1 style="color: white">
              Scrapl.us Açılıyor. <br>
              Lütfen bekleyiniz
            </h1>
            <ion-spinner name="crescent" color="light"/>
          </div>
        </div>
      </ion-content>
    </Transition>
  </ion-app>
</template>


<script>
import {
  IonApp,
  IonContent, IonSpinner,
} from '@ionic/vue';
import {defineComponent} from 'vue';
import {ref} from "@vue/runtime-core";
import Loading from "./components/Loading.vue";

export default defineComponent({
  name: 'LoadingContainer',
  components: {
    IonApp,
    Loading,
    IonContent,
    IonSpinner
  },
  setup() {
    let progress = ref(0);

    return {
      progress,
      progressCompleted: ref(false),
    };
  },
  mounted() {
    let _this = this;
    global.emitter.on('applicationProgress', function ({progress, error}) {
      if (error === null) {
        _this.progress = progress
        if (progress > 1) {
          setTimeout(() => {
            _this.progressCompleted = true;
          },400);
        }
      }
    })
  }
});
</script>
<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: all 1s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>