<template>
  <ion-progress-bar :value="progress"></ion-progress-bar>
</template>


<script>
import {
  IonProgressBar,
} from '@ionic/vue';
import {defineComponent} from 'vue';
import {ref} from "@vue/runtime-core";

export default defineComponent({
  name: 'Loading',
  components: {
    IonProgressBar
  },
  setup() {
    let progress = ref(0);

    return {
      progress,
    };
  },
  mounted() {
    let _this = this;
    global.emitter.on('applicationProgress',function ({progress,error}) {
      if (error === null) {
        _this.progress = progress
      }
    })
  }
});
</script>
<style>

</style>