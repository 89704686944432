import {api as apiConfig} from "../config";
import axios from "axios";
import Model from '../models/Model'
import {Model as BaseModel} from 'vue-api-query'
import {loadingController} from "@ionic/vue";


export class Api {
    constructor(cookieManager, api = apiConfig) {
        this.helper = global.helper;
        this.cookies = cookieManager;
        this.setNav = function (api) {

            this.isAuth = this.cookies.isKey('token') || this.ApiToken;
            this.authHeader = {};
            this.header = {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            };
            if (this.isAuth) {
                let token = this.cookies.get('token');
                if (token === null){
                    token = this.ApiToken;
                }
                this.authHeader = {'Authorization': 'Bearer ' + token};
            }
            this.nav = axios.create({
                baseURL: api.url,
                timeout: 30000,
                headers: {
                    ...this.header,
                    ...this.authHeader,
                }
            });
            BaseModel.$http = this.nav;
            this.Model = new Model();
        };
        this.setNav(api);

    }

    async get(uri) {
        return await this.nav.get(uri).catch((e) => {
            const message = this.helper.setErrorMessage(e.response);
            this.helper.setAlert('Hata', '', message).then(alert => alert.present());
            return e.response;
        });
    }

    async post(uri, data, config, showLoader = true) {
        let loading = {};
        if (showLoader) {
            loading = await loadingController
                .create({
                    message: 'Lütfen bekleyiniz...',
                    duration: 0,
                });

            await loading.present();
        }

        return await this.nav.post(uri, data, config)
            .catch((e) => {
                const message = this.helper.setErrorMessage(e.response);
                this.helper.setAlert('Hata', '', message).then(alert => alert.present());
                return e.response;
            }).finally(() => {
                    if (showLoader) {
                        loading.dismiss()
                    }
                }
            );
    }

    async put(uri, data, config) {
        const loading = await loadingController
            .create({
                message: 'Lütfen bekleyiniz...',
                duration: 0,
            });

        await loading.present();

        return await this.nav.put(uri, data, config)
            .catch((e) => {
                const message = this.helper.setErrorMessage(e.response);
                this.helper.setAlert('Hata', '', message).then(alert => alert.present());
                return e.response;
            }).finally(() => {
                    loading.dismiss()
                }
            );
    }

    async delete(uri, data, config) {
        let del = false;
        return await this.helper.setAlert('Silmek istediğinizden Emin Misiniz?', '', '',
            [{
                text: 'Evet', handler: () => {
                    del = true;
                }
            }, {text: 'Hayır'}]
        ).then(async alert => {
            await alert.present();
            await alert.onDidDismiss();
            if (del) {
                const loading = await loadingController
                    .create({
                        message: 'Lütfen bekleyiniz...',
                        duration: 0,
                    });

                await loading.present();

                return await this.nav.delete(uri, data, config)
                    .catch((e) => {
                        const message = this.helper.setErrorMessage(e.response);
                        this.helper.setAlert('Hata', '', message).then(alert => alert.present());
                        return e.response;
                    }).finally(() => {
                            loading.dismiss()
                        }
                    );
            }
        });
    }


    get auth() {
        return this.cacheAuth;
    }

    get consent() {
        return this.cacheConsent;
    }

    get settings() {
        return this.cacheSettings;
    }

    get cities() {
        return this.cacheCities;
    }

    get categories() {
        return this.cacheCategories;
    }

    get conditions() {
        return this.cacheConditions;
    }

    get workTypes() {
        return this.cacheWorkTypes;
    }

    get tonnageTypes() {
        return this.cacheTonnageTypes;
    }

    get priceTypes() {
        return this.cachePriceTypes;
    }

    get progress() {
        return this.cacheProgress
    }

    async getProgress() {
        this.cacheProgress = 0;
    }

    async setProgress(step = 15 / 100) {
        this.cacheProgress = this.progress + step;
        global.emitter.emit('applicationProgress', {progress: this.progress, error: null});
    }

    async getNeeded(Api) {
        await Api.getConsent();
        await Api.getSettings();
        await Api.getProgress();
        await Api.getWorkTypes();
        await Api.setProgress();
        await Api.getCities();
        await Api.setProgress();
        await Api.getCategories();
        await Api.setProgress();
        await Api.getConditions();
        await Api.setProgress();
        await Api.getPriceTypes();
        await Api.setProgress();
        await Api.getTonnageTypes();
        await Api.setProgress(1);
    }

    async getNotifications() {
        const notifications = await this.get('notifs');
        this.cacheNotifs = notifications?.data;
        return notifications?.data;
    }

    static async setAuth(Api) {
        await Api.getNeeded(Api)
        if (global.cookies.isKey('token') || Api.ApiToken) {
            const authData = Api.getAuth();
            global.emitter.emit('updateProfile', authData);

            return {
                ...await authData
            };
        }
        return {status: 200};
    }

    async getAuth() {
        const profile = await this.get('profile');
        this.cacheAuth = profile?.data?.data;
        return {...profile?.data?.data, status: profile.status};
    }

    async getSettings() {
        const settings = await this.get('settings');
        this.cacheSettings = settings?.data;
        return settings?.data;
    }

    async getConsent() {
        const consent = await this.get('consent');
        this.cacheConsent = consent?.data?.consent;
        return consent?.data?.consent;
    }

    async getCities() {
        const cities = await this.get('cities');
        this.cacheCities = cities?.data;
        return cities?.data;
    }

    async getCategories() {
        const categories = await this.get('categories');
        this.cacheCategories = categories?.data;
        return categories?.data;

    }

    async getConditions() {
        const conditions = await this.get('conditions');
        this.cacheConditions = conditions?.data;
        return conditions?.data;

    }

    async getPriceTypes() {
        const priceTypes = await this.get('price-types');
        this.cachePriceTypes = priceTypes?.data;
        return priceTypes?.data;

    }

    async getTonnageTypes() {
        const tonnageTypes = await this.get('tonnage-types');
        this.cacheTonnageTypes = tonnageTypes?.data;
        return tonnageTypes?.data;

    }

    async getWorkTypes() {
        const workTypes = await this.get('work-types');
        this.cacheWorkTypes = workTypes?.data;
        return workTypes?.data;
    }

    setToken(token) {
        this.cookies.set('token', token);
        this.ApiToken = token;
        this.setNav(apiConfig);
        global.emitter.emit('updateProfile',this.getAuth());
        //location.reload();
    }

    removeToken() {
        this.cookies.remove('token');
        this.setNav(apiConfig);
        location.reload();
    }

}

// Plugin installation function
export function api(app) {
    app.config.globalProperties.$api = global.api;
    app.config.globalProperties.$emitter = global.emitter;
    app.provide('api', global.api);
    app.provide('emitter', global.emitter);
}

export default api;