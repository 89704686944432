<template>
  <ion-app>
    <Layout/>
    <ion-modal :isOpen="isVipOpen" :backdrop-dismiss="true" class="paymentModal">

      <ion-header>
        <ion-toolbar>
          <ion-title>Ödeme sayfası</ion-title>
          <ion-buttons slot="end">
            <ion-button @click="dismissModal()">Kapat</ion-button>
          </ion-buttons>
        </ion-toolbar>
      </ion-header>
      <ion-content style="height: 93vh">

        <iframe :src="formUrl" style="width: 100%;height: 64%;">

        </iframe>
      </ion-content>
    </ion-modal>
  </ion-app>
</template>


<script>
import {
  IonApp, IonButton, IonButtons, IonContent, IonHeader, IonModal, IonTitle, IonToolbar,
} from '@ionic/vue';
import {defineComponent} from 'vue';
import Layout from "./Layout.vue";

export default defineComponent({
  name: 'App',
  components: {
    IonToolbar, IonContent, IonButton, IonButtons, IonModal, IonTitle, IonHeader,
    Layout,
    IonApp,
  },
  data() {
    return {
      formUrl: null,
      isVipOpen: false,
    };
  },
  methods: {
    dismissModal() {
      this.isVipOpen = false;
      this.$helper.setToast('Premium olma işlemini yarıda kestiniz, lütfen tekrar deneyiniz!', 'dark').then(toast => toast.present());
    }
  },
  mounted() {
    let _this = this;
    this.$emitter.on('vipSet', (formUrl) => {
      _this.formUrl = formUrl;
      _this.isVipOpen = true;
      let interval = setInterval(() => {
        let _interval = interval;
        this.$api.getAuth().then((auth) => {
          if (auth.is_vip) {
            this.$helper.setToast('Premium olundu', 'dark').then(toast => toast.present());
            _this.isVipOpen = false;
            this.$emitter.emit('updateProfile', this.$api.getAuth());
            clearInterval(_interval);
          }
        })
      },'5000')

    });
  }
});
</script>
<style>
</style>