<template>

  <Transition name="fade">
    <ion-app v-if="calling_vip">
      <ion-content>
        <div class="site-content">
          <div class="landing-banner" style="background-color: #56B35A;">
            <div class="container">
              <div class="lb-info">
                <h2 style="color: #ffffff">Scrapl.us Premium</h2>
                <p style="color: #ffffff" class="">Sadece {{ getPrice() }}₺
                </p>
                <p style="color: #ffffff" class="">Merak etme premium üyelik işlemin tamamlandığında kaldığın yerden
                  devam edebileceksin!</p>
                <div class="lb-button">
                  <ion-button @click="handleCheckout" color="light">
                    <ion-icon :icon="diamondSharp()" slot="start"/>
                    &nbsp;PREMIUM OL
                  </ion-button>

                  <ion-button @click="handleCancel" color="dark">
                    &nbsp;Geri Dön
                  </ion-button>
                  <p style="margin-top: 20px;color: #ffffff">
                    Ödemeleriniz İyzico tarafından güvenle yönetilmektedir!
                  </p>
                  <div style="color: #ffffff">
                    <img src="assets/logo_band_colored.svg" style="width: 300px">
                  </div>
                </div>
              </div><!-- .lb-info -->
            </div>
          </div><!-- .landing-banner -->
          <div class="img-box-inner">
            <div class="container">
              <div class="title ld-title">
                <h2>Scrapl.us Premium nedir?</h2>
                <p>Scrapl.us Premium sayesinde pazarda bulunan ilanların sahiplerine erişimin kolaylaşır.
                  Normal hesap ile sadece bir seferlik mesaj atma hakkınız bulunur fakat premium üye ile
                  sınırsız mesaj ve istediğiniz zaman istediğiniz kişiyi arama hakkına sahip olursunuz!<br>
                  İstediğiniz zaman İyzico üzerinden yada hesap ayarlarından premium üyeliğinizi iptal
                  edebilirsiniz.</p>
              </div>
            </div>
          </div><!-- .img-box-inner -->

          <div
              style="height: 50px;background-color: #56b35a; color: white; font-size: 10px; padding: 10px;text-align: center">
            Scrapl.us premium {{ getPrice() }}₺/hafta fiyatı şuan için geçerlidir. Her an fiyatlandırmalar değişebilir.
          </div><!-- .landing-banner -->
        </div>
      </ion-content>
    </ion-app>
  </Transition>
</template>


<script>
import {
  IonApp, IonButton, IonContent, IonIcon, isPlatform,
} from '@ionic/vue';
import {defineComponent} from 'vue';
import {diamondSharp} from "ionicons/icons";
import config from "./config";
import { AppLauncher } from '@capacitor/app-launcher';


export default defineComponent({
  name: 'Vip',
  methods: {
    async openLink(url) {
      await AppLauncher.canOpenUrl({ url: url });
      await AppLauncher.openUrl({ url: url });
    },
    diamondSharp() {
      return diamondSharp
    },
    dismissModal() {
      this.isVipOpen = false;
    },
    handleCancel() {
      this.calling_vip = false;
    },
    getPrice() {
      return global.api.settings.vip_price.data
    },
    async handleCheckout() {
      let content = await this.$api.get(
          "set-vip-api"
      );
      this.formUrl = config.api.webUrl + 'set-vip-content?user_id=' + global.api.auth.id + '&key=' + content.data.key + '&user_hash=' + content.data.user_hash;
      if (isPlatform('ios')) {
        this.openLink(this.formUrl)
      }else{
        this.$emitter.emit('vipSet', this.formUrl)
        this.isVipOpen = true;
        this.calling_vip = false;
      }


    },
  },
  components: {
    IonButton, IonIcon,
    IonContent,
    IonApp,
  },
  data() {
    return {
      calling_vip: false,
      isVipOpen: false,
      formUrl: null,
    }
  },
  mounted() {
    global.emitter.on('calling_vip', () => this.calling_vip = true)
  },
});
</script>
<style>

.fade-enter-active,
.fade-leave-active {
  transition: all 1s ease;
}

@media only screen and (max-width: 575px) {
  .landing-banner {
    padding: 50px;
  }

  iframe {
    height: 100% !important;
  }
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>