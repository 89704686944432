import { Model as BaseModel } from 'vue-api-query'
import {api} from "@/config";

export default class Model extends BaseModel {
    // Define a base url for a REST API
    baseURL() {
        return api.modelUrl
    }

    // Implement a default request method
    request(config) {
        return this.$http.request(config)
    }
}