import {createRouter, createWebHashHistory} from '@ionic/vue-router';

const authenticatedRoutes = [
    {
        path: '/loading',
        name: 'loading',
        component: () => import("@/LoadingContainer.vue"),
        meta: {requiresAuthentication: true},
    },
    {
        path: '/profile',
        name: 'profile',
        component: () => import("@/views/Profile"),
        meta: {requiresAuthentication: true},
    },
    {
        path: '/my-list',
        name: 'my-list',
        component: () => import("@/views/Listing"),
        meta: {requiresAuthentication: true},
    },
    {
        path: '/favorite-list',
        name: 'favorite-list',
        component: () => import("@/views/Listing"),
        meta: {requiresAuthentication: true},
    },
    {
        path: '/favorite-list',
        name: 'favorite-list',
        component: () => import("@/views/Listing"),
        meta: {requiresAuthentication: true},
    },
    {
        path: '/logout',
        name: 'logout',
        component: () => import("@/views/Login"),
        meta: {requiresAuthentication: true},
    },
    {
        path: '/notification',
        name: 'notification',
        component: () => import("@/views/Notification"),
        meta: {requiresAuthentication: true},
    },
    {
        path: '/message',
        name: 'message',
        component: () => import("@/views/Message"),
        meta: {requiresAuthentication: true},
    },
];
const routes = [
    {
        path: '/',
        redirect: '/home',
        meta: {requiresAuthentication: false},
    },
    {
        path: '/remove',
        name: 'remove',
        component: () => import("@/views/Remove"),
        meta: {requiresAuthentication: false},
    },
    {
        path: '/home',
        name: 'home',
        component: () => import("@/views/Home"),
        meta: {requiresAuthentication: false},
    },
    {
        path: '/login',
        name: 'login',
        component: () => import("@/views/Login"),
        meta: {requiresAuthentication: false},
    },
    {
        path: '/list',
        name: 'list',
        component: () => import("@/views/Listing"),
        meta: {requiresAuthentication: false},
    },
    {
        path: '/about-us',
        name: 'about',
        component: () => import("@/views/About"),
        meta: {requiresAuthentication: false},
    },
    {
        path: '/policies',
        name: 'policies',
        component: () => import("@/views/Policies"),
        meta: {requiresAuthentication: false},
    },
    {
        path: '/return-policies',
        name: 'return-policies',
        component: () => import("@/views/ReturnPolicies"),
        meta: {requiresAuthentication: false},
    },
    {
        path: '/contact',
        name: 'contact',
        component: () => import("@/views/Contact"),
        meta: {requiresAuthentication: false},
    },
    {
        path: '/list/:id',
        name: 'list.detail',
        component: () => import("@/views/Listing"),
        meta: {requiresAuthentication: false},
    },
    ...authenticatedRoutes
];

const router = createRouter({
    history: createWebHashHistory(process.env.BASE_URL),
    routes
})

const checkAuthStatus = async (to, from, next) => {
    document.querySelector('ion-modal.show-modal:not(.paymentModal)')?.remove();
    if (to.matched.some((r) => r.meta.requiresAuthentication)) {
        if (!(global.cookies.isKey('token')) && !(global.api.ApiToken)) {
            return next('/');
        }
    }
    if (to.matched.some((r) => r.path === '/login')) {
        if (global.cookies.isKey('token') || global.api.ApiToken) {
            return next('/');
        }
    }
    next();
};
router.beforeEach(checkAuthStatus)

export default router;