<script>

import {defineComponent} from "vue";
import HeaderComponent from "./components/HeaderComponent.vue";
import {IonContent, IonRouterOutlet} from "@ionic/vue";

export default defineComponent({
  components: {IonRouterOutlet, IonContent, HeaderComponent},
})
</script>

<template>
  <header-component/>
  <ion-content>
    <Transition>
      <ion-router-outlet id="main" :key="$route.fullPath"/>
    </Transition>
  </ion-content>
</template>

<style scoped>
.v-enter-active,
.v-leave-active {
  transition: all 1s;
}

.v-enter-from {
  transform: translate(100%, 0);
}

.v-leave-to {
  transform: translate(-100%, 0);
}
</style>