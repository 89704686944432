import {alertController, toastController} from "@ionic/vue";

export class Helper {

    constructor() {
    }

    async setAlert(header = "Hata", subHeader = "", message = "Hata Alındı Lütfen Tekrar Deneyiniz", buttons = [{text: "Tamam"}], options = {}) {
        return await alertController.create(
            {
                header: header,
                subHeader: subHeader,
                message: message,
                buttons: buttons,
                ...options
            }
        );
    }

    async setToast(message, color = 'dark', duration = 2000, showCloseButton = true, options = {}) {
        return await toastController.create({
            color: color,
            duration: duration,
            message: message,
            showCloseButton: showCloseButton,
            ...options
        });
    }

    setErrorMessage(response) {
        let message = ['Hata Oluştu!'];
        if (response) {
            const errors = response.data?.errors;
            if (errors) {
                message = Object.keys(errors).map((error) => {
                    return errors[error].join(', ')
                });
            }
        }

        return message.join("\n");
    }

}

// Plugin installation function
export function helper(app) {
    app.config.globalProperties.$helper = global.helper;
    app.provide('helper', app.config.globalProperties.$helper);
    return app.config.globalProperties.$helper;
}

export function helperGlobal() {
    return new Helper();
}

export default {helper, helperGlobal};