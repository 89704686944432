export const api = {
    domain : process.env.VUE_APP_API_DOMAIN ?? 'hurda.test',
    webDomain : process.env.VUE_APP_WEB_DOMAIN ?? 'hurda.test',
    scheme : process.env.VUE_APP_API_IS_HTS === 'true' ? 'https' : 'http',
    extension : process.env.VUE_APP_API_EXTENSION ?? 'api',
    url : null,
    webUrl: null,
};
api.url = api.scheme + '://' + api.domain + '/' + api.extension + '/';
api.webUrl = api.scheme + '://' + api.webDomain + '/';
api.modelUrl = api.scheme + '://' + api.domain + '/' + api.extension;



export default {api};