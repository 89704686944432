import {createApp} from 'vue'
import LoadingContainer from "./LoadingContainer.vue";
import App from './App.vue'
import router from './router';
import {IonicVue} from '@ionic/vue';

import '@ionic/vue/css/core.css';
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './theme/style.css';
import './theme/responsive.css';
import './theme/font/jost/stylesheet.css';
import './theme/font/cormorant_garamond/stylesheet.css';

import googleMap from '@fawmi/vue-google-maps';
import VueCookies, {useCookies} from "vue3-cookies";
import {Api, api} from "./apiHelper";
import {helper, helperGlobal} from "./helper";
import mitt from 'mitt';
//import {declareNotification} from "./notification";
import Vip from "./Vip.vue";

import {App as CapApp} from "@capacitor/app";

CapApp.addListener('backButton', function(event) {
    return event;
});

const emitter = mitt();
const {cookies} = useCookies();
global.emitter = emitter;
global.cookies = cookies;
global.helper = helperGlobal();
global.api = new Api(global.cookies);

const loader = createApp(LoadingContainer)
    .use(IonicVue);
loader.mount('#loader');

const vip = createApp(Vip)
    .use(IonicVue)
    .use(VueCookies)
    .use(router)
    .use(helper)
    .use(api);
vip.mount('#vip');


// eslint-disable-next-line no-unused-vars
const app = createApp(App)
    .use(IonicVue)
    .use(VueCookies)
    .use(router)
    .use(helper)
    .use(api)
    .use(googleMap, {load: {key: "AIzaSyCpUh0DqLPMG8aUkjRswyFHCc7wDy6XLV8"}});
global.app = app;
Api.setAuth(global.api).then((res) => {
    if (res.status !== 200) {
        global.cookies.remove('token');
        global.api.removeToken();
    }

    global.emitter.emit('applicationLoaded');
    router.isReady().then(() => {
        //declareNotification();
        app.mount('#app');
        setTimeout(() => {
            loader.unmount('#loader');
        }, 500)
    });
});